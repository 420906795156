// Jquery, Bootstrap, Jquery-ui and their plugins
// noinspection ES6UnusedImports
import "../assets/js/jquery-1.12.4.min";
// noinspection ES6UnusedImports
//import "../assets/vendor/jquery-ui-custom/jquery-ui-1.9.2.custom.min";
// noinspection ES6UnusedImports
import "../assets/vendor/jquery.tools.min";
// noinspection ES6UnusedImports
import "../assets/vendor/bootstrap/js/bootstrap.min";
// noinspection ES6UnusedImports
import "../assets/vendor/bootstrap/js/bootstrap.offcanvas.min";
// noinspection ES6UnusedImports
import "../vendor/components/jquery-cookie/jquery.cookie";
// noinspection ES6UnusedImports
import "../assets/vendor/bootstrap-datepicker/js/bootstrap-datepicker";
// noinspection ES6UnusedImports
import "../assets/vendor/bootstrap-datepicker/locales/bootstrap-datepicker.de.min";
// noinspection ES6UnusedImports
import "../assets/vendor/bootstrap-datepicker/locales/bootstrap-datepicker.fr.min";
// noinspection ES6UnusedImports
import "../assets/vendor/bootstrap-datepicker/locales/bootstrap-datepicker.es.min";
// noinspection ES6UnusedImports
import "../assets/vendor/bootstrap-datepicker/locales/bootstrap-datepicker.pt-BR.min";
// noinspection ES6UnusedImports
import "cropperjs";
// noinspection ES6UnusedImports
import "jquery-cropper";
// noinspection ES6UnusedImports
import "../assets/vendor/html2canvas/html2canvas.min";
// noinspection ES6UnusedImports
import "slick-carousel";

// import Splide from '@splidejs/splide';
// import SimpleLightbox from "simplelightbox";

// Datatables and Plugins
// noinspection ES6UnusedImports
import "../vendor/datatables/datatables/media/js/jquery.dataTables";
// noinspection ES6UnusedImports
import "../vendor/drmonty/datatables-fixedheader/js/dataTables.fixedHeader";
// noinspection ES6UnusedImports
import "../vendor/drmonty/datatables-fixedcolumns/js/dataTables.fixedColumns";

// Select2 and Plugins
// noinspection ES6UnusedImports
import "../vendor/select2/select2/dist/js/select2";
// noinspection ES6UnusedImports
import "../vendor/components/select2/dist/js/i18n/de";

// Chartist and Plugins
// noinspection ES6UnusedImports
import "../assets/vendor/chartist-js/chartist";
// noinspection ES6UnusedImports
import "../assets/vendor/chartist-js/chartist-plugin-axistitle.min";


import * as L from 'leaflet';
import * as Libre from 'maplibre-gl';
import 'leaflet.markercluster';
import '@maplibre/maplibre-gl-leaflet';

// noinspection ES6UnusedImports
import "../assets/vendor/scrolltopcontrol/scrolltopcontrol";
// noinspection ES6UnusedImports
import "../assets/js/custom";
// noinspection ES6UnusedImports
import "../assets/js/faq";
// noinspection ES6UnusedImports
import "../assets/js/ags_administration";
// noinspection ES6UnusedImports
import "../assets/js/pagination";
// noinspection ES6UnusedImports
import "../assets/js/ce_katalog";
// noinspection ES6UnusedImports
import "../assets/js/searchContactForm";
// noinspection ES6UnusedImports
import "../assets/js/poster_generator";
// noinspection ES6UnusedImports
import "../assets/js/adminAuswertung";
// noinspection ES6UnusedImports
import "../assets/js/blog";
// noinspection ES6UnusedImports
import "../assets/js/blog_overview";
// noinspection ES6UnusedImports
import "../assets/js/blog_editor";
// noinspection ES6UnusedImports
import "../assets/js/blog_post";
// noinspection ES6UnusedImports
import "../assets/js/common_functions";
import "../assets/js/jumpmarks";
// noinspection ES6UnusedImports
import "../assets/js/partner-local";
// noinspection ES6UnusedImports
import "../assets/js/city-administraion-events";
// noinspection ES6UnusedImports
import "../assets/js/dashboard_teams";
// noinspection ES6UnusedImports
import "../assets/js/utility";
// noinspection ES6UnusedImports
import "../assets/js/wrw_table";
import "../assets/js/light-box";
// noinspection ES6UnusedImports
import "../assets/js/flyer-generator";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-initialization";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-validation";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-form";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-add-subcity";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-shoppingBasket-init";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-shoppingBasket-update";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-shoppingBasket-calculation";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-submit-form";
// noinspection ES6UnusedImports
import "../assets/js/CitySignup/citySignup-late-registration";
// noinspection ES6UnusedImports
import "../assets/js/testUI";
// noinspection ES6UnusedImports
import "../assets/js/promotion_overview";
// noinspection ES6UnusedImports
import "../assets/js/quillEditor";

import Quill from 'quill';
window.Quill = Quill;
// noinspection ES6UnusedImports
import "../assets/js/awardShow";


window.$(document).ready(function () {
    window.$('[data-toggle="popover"]').popover({trigger: 'hover click'});
});

/*
document.addEventListener( 'DOMContentLoaded', function() {
    // Splide image slider
        var splide = new Splide( '.splide', {
        type   : 'loop',
        perPage: 3,
        focus  : 'center',
        height : '40rem',
      } );
    splide.mount();
} );
*/
